import { onMounted, ref, Ref } from "vue";

export const useMobile = () => {
  const ismobile: Ref<boolean | null> = ref(null);
  let prevValue: Ref<number | null> = ref(null)

  const checkMode = (width: number) => {
    if (width < 1024) {
      if (ismobile.value === false || ismobile.value === null) {
        ismobile.value = true;
      }
    } else if (ismobile.value === true || ismobile.value === null) {
      ismobile.value = false;
    }
  };

  onMounted(() => {
    useResizeObserver(document.querySelector("header"), (entries) => {
      try {
        const entry = entries[0];
        const { width } = entry.contentRect;

        if(prevValue.value === null || prevValue.value !== width){
          prevValue.value = width;

          checkMode(width);
        }
      } catch(e){
        checkMode(0);
      }
    });
  });

  return {
    ismobile,
  };
};

import { Ref, watch, nextTick } from "vue";
import { findDeep } from "deepdash-es/standalone";
import { IDeepEntry } from "deepdash-es/IDeepEntry";
import { useMobile } from "~/composables/useMobile";
import { useHeader } from "~/composables/useHeader";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

type Link = {
  level: number;
  link: Element;
  parentUl: Element | null;
  parentLi: Element | null;
  flyout: Element | null;
  children: Link[];
};

type State = {
  "level-0": Link | null;
  "level-1": Link[];
};

type Timeout = {
  timer: number;
  link: Link;
};

type ClickEventTargetTreeItem = Omit<IDeepEntry, "value"> & { value: Link };

const siblings = (element: Element) =>
  [].slice.call(element.parentNode?.children).filter((child) => {
    return child !== element;
  }) as Element[];

export const useCustomNavigation = (header: Ref<Element>, mainnav: Ref<Element>) => {
  const { ismobile } = useMobile();
  const { isDesktopFlyoutOpened, isMobileDrawerOpened } = useHeader();

  const state: State = {
    "level-0": null,
    "level-1": [],
  };

  const timeouttime: number = 400;

  let timeouts: Timeout[] = [];

  const walkDOM = (mainnav: Element | null, level = 0, parentItem: Link | null = null) => {
    let linkElements: Element[] = [];

    // collect link elements in DOM having data-level attribute
    if (parentItem === null && mainnav) {
      linkElements = [...mainnav.querySelectorAll(`[data-level="${level}"]`)];
    } else if (parentItem && parentItem.flyout) {
      linkElements = [...parentItem.flyout.querySelectorAll(`[data-level="${level}"]`)];
    }

    // map link elements to link items
    return [...linkElements].map((linkElement) => {
      const link: Link = {
        level,
        link: linkElement,
        parentUl: linkElement.closest("ul"),
        parentLi: linkElement.closest("li"),
        flyout: linkElement.nextElementSibling,
        children: [],
      };

      link.children = walkDOM(mainnav, level + 1, link);

      return link;
    });
  };

  let ti = 0;

  const checkFixedPosition = (prevent = false) => {
    if (header.value && ismobile.value === false && isMobileDrawerOpened.value === false) {
      if (header.value instanceof HTMLElement) {
        if (document.body.classList.contains("has-stage")) {
          if (window.scrollY > 50) {
            (header.value as HTMLElement).classList.add("showbackground");
            (header.value as HTMLElement).classList.add("byscroll");

            if (prevent === false) {
              window.clearTimeout(ti);

              ti = window.setTimeout(() => {
                (header.value as HTMLElement).classList.remove("byscroll");
              }, 300);
            }
          } else {
            (header.value as HTMLElement).classList.remove("byscroll");
            (header.value as HTMLElement).classList.remove("showbackground");
          }
        }
      }
    }
  };

  const openLevel0 = (link: Link) => {
    state["level-0"] = link;

    if (header.value) {
      (header.value as HTMLElement).classList.add("showbackdrop");
      (header.value as HTMLElement).classList.add("showbackground");
    }

    document.querySelector(`[data-id="${link.link.dataset.id}"]`).classList.add("active");
    link.link.classList.add("active");
    link.parentUl?.classList.add("active");
    link.parentLi?.classList.add("active");

    if (ismobile.value === false) {
      isDesktopFlyoutOpened.value = true;
    }

    if (ismobile.value) {
      if (link.parentUl) {
        siblings(link.parentUl).forEach((siblingUl) => {
          siblingUl?.classList.add("important-hidden");
        });
      }

      if (link.parentLi) {
        siblings(link.parentLi).forEach((siblingLi) => {
          siblingLi.classList.add("important-hidden");
        });
      }
    }
  };

  const openLevel1 = (link: Link) => {
    state["level-1"].push(link);

    link.link.classList.add("active");
  };

  const closeLevel0 = (link: Link) => {
    state["level-0"] = null;

    if (header.value) {
      (header.value as HTMLElement).classList.remove("showbackdrop");
      (header.value as HTMLElement).classList.remove("showbackground");
    }

    checkFixedPosition(true);

    document.querySelector(`[data-id="${link.link.dataset.id}"]`).classList.remove("active");
    link.link.classList.remove("active");
    link.parentUl?.classList.remove("active");
    link.parentLi?.classList.remove("active");

    if (ismobile.value === false) {
      isDesktopFlyoutOpened.value = false;
    }

    if (link.parentUl) {
      siblings(link.parentUl).forEach((siblingUl) => {
        siblingUl.classList.remove("important-hidden");
      });
    }

    if (link.parentLi) {
      siblings(link.parentLi).forEach((siblingLi) => {
        siblingLi.classList.remove("important-hidden");
      });
    }

    state["level-1"].forEach((link) => {
      closeLevel1(link);
    });
  };

  const closeLevel1 = (link: Link) => {
    state["level-1"] = state["level-1"].filter((l) => {
      return l !== link;
    });

    link.link.classList.remove("active");
  };

  const closeAllLevel = () => {
    if (state["level-1"]) {
      state["level-1"].forEach((link) => {
        closeLevel1(link);
      });
    }

    if (state["level-0"]) {
      closeLevel0(state["level-0"]);
    }
  };

  const openMobileDrawer = () => {
    isMobileDrawerOpened.value = true;

    if (header.value && mainnav.value) {
      (header.value as HTMLElement).classList.add("showdrawer");
      disableBodyScroll(mainnav.value);
    }
  };

  const closeMobileDrawer = () => {
    isMobileDrawerOpened.value = false;

    if (header.value && mainnav.value) {
      (header.value as HTMLElement).classList.remove("showdrawer");
      enableBodyScroll(mainnav.value);
    }

    closeAllLevel();
  };

  const toggleMobileDrawer = () => {
    if (isMobileDrawerOpened.value) {
      closeMobileDrawer();
    } else {
      openMobileDrawer();
    }
  };

  const mouseEnterListeners = new Map<Link, Function>();
  const mouseLeaveListeners = new Map<Link, Function>();
  const flyoutMouseEnterListeners = new Map<Link, Function>();
  const flyoutMouseLeaveListeners = new Map<Link, Function>();

  const bindMouseMoveEvents = (links: Link[]) => {
    const onLinkMouseEnter = (link: Link) => {
      if (ismobile.value === false) {
        if (state["level-0"] !== null && state["level-0"] !== link) {
          const timeout = timeouts.find((timeout) => timeout.link === state["level-0"]);

          timeouts = timeouts.filter((timeout) => timeout.link !== state["level-0"]);

          if (timeout) {
            clearTimeout(timeout.timer);
          }

          closeLevel0(state["level-0"]);
        }

        timeouts.forEach((timeout) => {
          clearTimeout(timeout.timer);
        });

        timeouts = timeouts.filter((timeout) => timeout.link !== link);

        if (link.children.length) {
          openLevel0(link);
        }
      }
    };
    const onLinkMouseLeave = (link: Link) => {
      if (ismobile.value === false) {
        if (link.children.length) {
          timeouts.push({
            timer: window.setTimeout(() => {
              timeouts = timeouts.filter((timeout) => timeout.link !== link);

              closeLevel0(link);
            }, timeouttime),
            link,
          });
        }
      }
    };
    const onFlyoutMouseEnter = (link: Link) => {
      if (ismobile.value === false) {
        const timeout = timeouts.find((timeout) => timeout.link === link);

        timeouts = timeouts.filter((timeout) => timeout.link !== link);

        if (timeout) {
          clearTimeout(timeout.timer);
        }
      }
    };
    const onFlyoutMouseLeave = (link: Link) => {
      if (ismobile.value === false) {
        timeouts.push({
          timer: window.setTimeout(() => {
            timeouts = timeouts.filter((timeout) => timeout.link !== link);

            closeLevel0(link);
          }, timeouttime),
          link,
        });
      }
    };

    mouseEnterListeners.forEach((value, key) => {
      if (key && key.link) {
        try {
          (key.link as HTMLElement).removeEventListener("mouseenter", value);
        } catch (e) {
          // fail silent
        }
      }
    });

    mouseLeaveListeners.forEach((value, key) => {
      if (key && key.link) {
        try {
          (key.link as HTMLElement).removeEventListener("mouseleave", value);
        } catch (e) {
          // fail silent
        }
      }
    });

    flyoutMouseEnterListeners.forEach((value, key) => {
      if (key && key.flyout) {
        try {
          (key.flyout as HTMLElement).removeEventListener("mouseenter", value);
        } catch (e) {
          // fail silent
        }
      }
    });

    flyoutMouseLeaveListeners.forEach((value, key) => {
      if (key && key.flyout) {
        try {
          (key.flyout as HTMLElement).removeEventListener("mouseleave", value);
        } catch (e) {
          // fail silent
        }
      }
    });

    // event listener for mobile mode
    links.forEach((link) => {
      if (link.level === 0) {
        const linkMouseEnter = () => {
          onLinkMouseEnter(link);
        };
        const linkMouseLeave = () => {
          onLinkMouseLeave(link);
        };
        const flyoutMouseEnter = () => {
          onFlyoutMouseEnter(link);
        };
        const flyoutMouseLeave = () => {
          onFlyoutMouseLeave(link);
        };

        mouseEnterListeners.set(link, linkMouseEnter);
        mouseLeaveListeners.set(link, linkMouseLeave);
        flyoutMouseEnterListeners.set(link, flyoutMouseEnter);
        flyoutMouseLeaveListeners.set(link, flyoutMouseLeave);

        link.link.addEventListener("mouseenter", linkMouseEnter);
        link.link.addEventListener("mouseleave", linkMouseLeave);
        link.flyout?.addEventListener("mouseenter", flyoutMouseEnter);
        link.flyout?.addEventListener("mouseleave", flyoutMouseLeave);
      }
    });
  };

  const bindMouseClickEvent = (links: Link[]) => {
    if (header.value) {
      const onHeaderClick = (e: MouseEvent) => {
        const target = e.target?.closest("[data-level]");

        const treeItem = findDeep(
          links,
          (value) => {
            const { link } = value;

            if (link === target) {
              return true;
            }

            if (link && target && link !== undefined && target !== undefined) {
              if (link.dataset.id === target.dataset.id) {
                return true;
              }
            }

            return false;
          },
          {
            leavesOnly: false,
            childrenPath: ["children"],
          },
        ) as ClickEventTargetTreeItem | undefined;

        if (ismobile.value === true) {
          if (treeItem !== undefined && treeItem.value !== undefined) {
            const link = treeItem.value;

            if (link.level === 0) {
              if (link.children.length > 0) {
                e.preventDefault();
                e.stopPropagation();

                if (state["level-0"] === link) {
                  closeLevel0(link);
                } else {
                  openLevel0(link);
                }
              } else {
                closeMobileDrawer();
              }

              return false;
            } else if (link.level === 1) {
              if (link.flyout) {
                e.preventDefault();
                e.stopPropagation();

                if (state["level-1"].includes(link)) {
                  closeLevel1(link);
                } else {
                  openLevel1(link);
                }
              } else {
                closeMobileDrawer();
              }

              return false;
            }
          }
        } else {
          if (treeItem !== undefined && treeItem.value !== undefined) {
            if (isDesktopFlyoutOpened.value && target.nodeName.toLowerCase() === "a") {
              closeAllLevel();
            }
          }
        }
      };

      (header.value as HTMLElement)?.removeEventListener("click", onHeaderClick);
      (header.value as HTMLElement)?.addEventListener("click", onHeaderClick);
    }
  };

  const bindScrollListener = () => {
    document.addEventListener(
      "scroll",
      () => {
        checkFixedPosition();
      },
      { passive: true },
    );
  };

  onMounted(() => {
    watch(ismobile, (current) => {
      if (!current) {
        closeMobileDrawer();
      }
    });
  });

  onUnmounted(() => {
    clearAllBodyScrollLocks();
  });

  return {
    ismobile,
    walkDOM,
    bindMouseMoveEvents,
    bindMouseClickEvent,
    closeAllLevel,
    toggleMobileDrawer,
    closeMobileDrawer,
    bindScrollListener,
  };
};
